import axiosInstance from "src/helpers/axiosInstance";
import moment from "moment";

export const exportAllFile = (props) => onResponse => {

    try {
        let base_url = props?.url;
        if (props?.search?.account_number !== undefined && props?.search?.account_number !== '' && props?.search?.account_number !== null) {
            base_url += `account_number=${props?.search?.account_number}&`
        }
        if (props?.search?.patient !== undefined && props?.search?.patient !== '' && props?.search?.patient !== null) {
            base_url += `patient=${props?.search?.patient}&`
        }
        if (props?.search?.payer !== undefined && props?.search?.payer !== '' && props?.search?.payer !== null) {
            base_url += `payer=${props?.search?.payer}&`
        }
        if (props?.search?.submission_date !== undefined && props?.search?.submission_date !== '' && props?.search?.submission_date !== null) {
            base_url += `submission_date=${moment(props?.search?.submission_date).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.service_date !== undefined && props?.search?.service_date !== '' && props?.search?.service_date !== null) {
            base_url += `service_date=${moment(props?.search?.service_date).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.paid_amount !== undefined && props?.search?.paid_amount !== '' && props?.search?.paid_amount !== null) {
            base_url += `paid_amount=${props?.search?.paid_amount}&`
        }
        if (props?.search?.client_name !== undefined && props?.search?.client_name !== '' && props?.search?.client_name !== null) {
            base_url += `client_name=${props?.search?.client_name}&`
        }
        if (props?.search?.menu !== undefined && props?.search?.menu !== '' && props?.search?.menu !== null) {
            base_url += `menu=${props?.search?.menu}&`
        }
        if (props?.search?.filename !== undefined && props?.search?.filename !== '' && props?.search?.filename !== null) {
            base_url += `filename=${props?.search?.filename}&`
        }
        if (props?.search?.username !== undefined && props?.search?.username !== '' && props?.search?.username !== null) {
            base_url += `username=${props?.search?.username}&`
        }
        if (props?.search?.date !== undefined && props?.search?.date !== '' && props?.search?.date !== null) {
            base_url += `date=${moment(props?.search?.date).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.created_date !== undefined && props?.search?.created_date !== '' && props?.search?.created_date !== null) {
            base_url += `created_date=${moment(props?.search?.created_date).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.billed_amount !== undefined && props?.search?.billed_amount !== '' && props?.search?.billed_amount !== null) {
            base_url += `billed_amount=${props?.search?.billed_amount}&`
        }
        if (props?.search?.patres !== undefined && props?.search?.patres !== '' && props?.search?.patres !== null) {
            base_url += `patres=${props?.search?.patres}&`
        }
        if (props?.search?.file_creation_date !== undefined && props?.search?.file_creation_date !== '' && props?.search?.file_creation_date !== null) {
            base_url += `file_creation_date=${moment(props?.search?.file_creation_date).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.check_number !== undefined && props?.search?.check_number !== '' && props?.search?.check_number !== null) {
            base_url += `check_number=${props?.search?.check_number}&`
        }
        if (props?.search?.dfi_check_number !== undefined && props?.search?.dfi_check_number !== '' && props?.search?.dfi_check_number !== null) {
            base_url += `dfi_check_number=${props?.search?.dfi_check_number}&`
        }
        if (props?.search?.amount !== undefined && props?.search?.amount !== '' && props?.search?.amount !== null) {
            base_url += `amount=${props?.search?.amount}&`
        }
        if (props?.search?.identification_number !== undefined && props?.search?.identification_number !== '' && props?.search?.identification_number !== null) {
            base_url += `identification_number=${props?.search?.identification_number}&`
        }
        if (props?.search?.receiving_company_name !== undefined && props?.search?.receiving_company_name !== '' && props?.search?.receiving_company_name !== null) {
            base_url += `receiving_company_name=${props?.search?.receiving_company_name}&`
        }
        if (props?.search?.sender_identification !== undefined && props?.search?.sender_identification !== '' && props?.search?.sender_identification !== null) {
            base_url += `sender_identification=${props?.search?.sender_identification}&`
        }
        if (props?.search?.receiver_identification !== undefined && props?.search?.receiver_identification !== '' && props?.search?.receiver_identification !== null) {
            base_url += `receiver_identification=${props?.search?.receiver_identification}&`
        }
        if (props?.search?.physical_record_length !== undefined && props?.search?.physical_record_length !== '' && props?.search?.physical_record_length !== null) {
            base_url += `physical_record_length=${props?.search?.physical_record_length}&`
        }
        if (props?.search?.originator_identification !== undefined && props?.search?.originator_identification !== '' && props?.search?.originator_identification !== null) {
            base_url += `originator_identification=${props?.search?.originator_identification}&`
        }
        if (props?.search?.bank_reference_number !== undefined && props?.search?.bank_reference_number !== '' && props?.search?.bank_reference_number !== null) {
            base_url += `bank_reference_number=${props?.search?.bank_reference_number}&`
        }
        if (props?.search?.customer_reference_number !== undefined && props?.search?.customer_reference_number !== '' && props?.search?.customer_reference_number !== null) {
            base_url += `customer_reference_number=${props?.search?.customer_reference_number}&`
        }
        if (props?.search?.payee !== undefined && props?.search?.payee !== '' && props?.search?.payee !== null) {
            base_url += `payee=${props?.search?.payee}&`
        }
        if (props?.search?.nacha_check_number !== undefined && props?.search?.nacha_check_number !== '' && props?.search?.nacha_check_number !== null) {
            base_url += `nacha_check_number=${props?.search?.nacha_check_number}&`
        }
        if (props?.search?.remit_amount !== undefined && props?.search?.remit_amount !== '' && props?.search?.remit_amount !== null) {
            base_url += `remit_amount=${props?.search?.remit_amount}&`
        }
        if (props?.search?.check_effective_date !== undefined && props?.search?.check_effective_date !== '' && props?.search?.check_effective_date !== null) {
            base_url += `check_effective_date=${moment(props?.search?.check_effective_date).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.date_matched !== undefined && props?.search?.date_matched !== '' && props?.search?.date_matched !== null) {
            base_url += `date_matched=${moment(props?.search?.date_matched).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.check_date !== undefined && props?.search?.check_date !== '' && props?.search?.check_date !== null) {
            base_url += `check_date=${moment(props?.search?.check_date).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.payment_type !== undefined && props?.search?.payment_type !== '' && props?.search?.payment_type !== null) {
            base_url += `payment_type=${props?.search?.payment_type}&`
        }
        if (props?.search?.file_source !== undefined && props?.search?.file_source !== '' && props?.search?.file_source !== null) {
            base_url += `file_source=${props?.search?.file_source}&`
        }
        if (props?.search?.deposit_date !== undefined && props?.search?.deposit_date !== '' && props?.search?.deposit_date !== null) {
            base_url += `deposit_date=${moment(props?.search?.deposit_date).format('YYYY-MM-DD')}&`
        }
        if (props?.search?.check_matched !== undefined && props?.search?.check_matched !== '' && props?.search?.check_matched !== null) {
            base_url += `check_matched=${moment(props?.search?.check_matched).format('YYYY-MM-DD')}&`
        }




        if (typeof (props?.model_number) !== undefined && props?.model_number !== '') {
            base_url += `model_number=${props?.model_number}&`
        }
        if (typeof (props?.type) !== undefined && props?.type !== '') {
            base_url += `csv_pdf=${props?.type}&`
        }
        if (props?.master !== undefined && props?.master !== '' && props?.master !== null) {
            base_url += `master=${props?.master}&`
        }
        else {
            base_url += `master=&`
        }

        if (props?.matched !== undefined && props?.matched !== '' && props?.matched !== null) {
            base_url += `match=${props?.matched}&`
        }
        if (props?.reason !== undefined && props?.reason !== '' && props?.reason !== null) {
            base_url += `reason=${props?.reason}&`
        }

        if (props?.search?.categeory_name !== undefined && props?.search?.categeory_name !== '' && props?.search?.categeory_name !== null) {
            base_url += `categeory_name=${props?.search?.categeory_name}&`
        }

        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const sendRemittenceMail = (props) => onResponse => {

    try {
        const master = props?.master ? props?.master : '';
        const queryset_id_list = props?.queryset_id_list && props?.queryset_id_list !== undefined && props?.queryset_id_list?.length ? props?.queryset_id_list : ''
        let matched = ''
        if (props.matched !== 'undefined' && props.matched !== 0 && props.matched !== 'null') {
            matched = props.matched
        }
        axiosInstance.get('/reports/pdf_csv?model_number=' + props.model_number + '&csv_pdf=' + props.type + '&master=' + master + '&queryset_id=' + queryset_id_list + '&match=' + matched + '&mailsent=true')
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const getGlobalSearch = (props) => onResponse => {

    try {
        let base_url = 'dashboard/global-search?'
        if (typeof (props?.search) !== 'undefined' && props?.search !== '') {
            base_url += `search=${props?.search}`
        }



        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const getDownloadFileStatus = (props) => onResponse => {

    try {
        let base_url = 'dashboard/get-restore-status?'
        if (typeof (props?.link) !== 'undefined' && props?.link !== '') {
            base_url += `url_link=${props?.link}&`
        }
        if (typeof (props?.xs) !== 'undefined' && props?.xs !== '') {
            base_url += `xs=${props?.xs}`
        }



        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const getGlacierFile = (props) => onResponse => {

    try {
        let base_url = 'dashboard/get-glacier?'
        if (typeof (props?.link) !== 'undefined' && props?.link !== '') {
            base_url += `url_link=${props?.link}&`
        }
        if (typeof (props?.xs) !== 'undefined' && props?.xs !== '') {
            base_url += `xs=${props?.xs}`
        }



        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const syncXtractData = () => onResponse => {

    try {
        let base_url = 'revarc_sync/sync-records'
        axiosInstance.post(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};