import { Tooltip } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold", // Make header text bold
    },
  },
};
const small_headers = ["from_page", "sl_no","status"];
const large_headers = ["file_name", "reject_reason"];
const width_arr = [
  {key:'sl_no',width:80,type:'num'},
  {key:'from_page',width:80,type:'num'},
  {key:'status',width:80,type:'text'},
  {key:'file_name',width:300,type:'text'},
  {key:'reject_reason',width:300,type:'text'},
  {key:'transaction_type',width:150,type:'text'},

]
const ExceptionReportModal = ({ toggleReportPopup, setToggleReportPopup, reportData, setReportData }) => {

  const columns1 = [
    {
      name: "Image Name",
      id: "image_name",
      selector: (row) => row?.image_name,
      cell: (row) => (
        <Tooltip title={row?.image_name} disableInteractive>
          <div className="text-truncate" style={{ maxWidth: "190px", margin: "0 auto" }}>
            <span> {row?.image_name ? row?.image_name : "-"}</span>
          </div>
        </Tooltip>
      ),

      width: "200px",

      left: true,
    },
    {
      name: "From Page",
      id: "from_page",
      selector: (row) => (row?.from_page ? row?.from_page : "-"),
      width: "100px",

      right: true,
    },
    {
      name: "To Page",
      id: "to_page",
      selector: (row) => (row?.to_page ? row?.to_page : "-"),
      width: "100px",

      right: true,
    },
    {
      name: "Exception Type",
      id: "exception_type",
      selector: (row) => (row?.exception_type ? row?.exception_type : "-"),
      width: "200px",

      left: true,
    },
    {
      name: "Check #",
      id: "check",
      selector: (row) => (row?.check ? row?.check : "-"),
      width: "150px",

      left: true,
    },
    {
      name: "Amount",
      id: "amount",
      selector: (row) => (row?.amount ? row?.amount : "-"),
      width: "100px",

      right: true,
    },
    {
      name: "Description",
      id: "description",
      selector: (row) => (row?.description ? row?.description : "-"),
      cell: (row) => (
        <Tooltip title={row?.description} disableInteractive>
          <div className="text-truncate" style={{ maxWidth: "190px", margin: "0 auto" }}>
            <span> {row?.description ? row?.description : "-"}</span>
          </div>
        </Tooltip>
      ),
      width: "200px",

      left: true,
    },
  ];
  const columns = reportData?.header?.map((col) => ({
    name: col.label,
    id: col.key,
    selector: (row) => row?.[col.key],
    cell: (row) => (
      <Tooltip title={row?.[col.key]} disableInteractive>
        <div className="text-truncate" style={{ maxWidth: `${width_arr?.find(i=>i.key==col.key)?.width-10}px` }}>
          <span>{row?.[col.key] ? row?.[col.key] : "-"}</span>
        </div>
      </Tooltip>
    ),
    width: `${width_arr?.find(i=>i.key==col.key)?.width}px`,
    left: width_arr?.find(i=>i.key==col.key)?.type=='text'?true:false, // Adjust based on the alignment needed
    right:width_arr?.find(i=>i.key==col.key)?.type=='num'?true:false
  }));
  return (
    <Modal
      size="xl"
      show={toggleReportPopup}
      onHide={() => {
        setToggleReportPopup(false);
        setReportData([]);
      }}
      centered
      className="manual-tat"
    >
      <Modal.Header closeButton>
        <Modal.Title className="table-report-modelTitle">Exception Report</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        {/* <table className="table history-log-table">
          <thead>
            <TR>
              <TH className="th-right">From Page</TH>
              <TH className="th-right">To Page</TH>
              <TH className="th-left">Exception Type</TH>
              <TH className="th-left">Check #</TH>
              <TH className="th-right">Amount</TH>
              <TH className="th-left">Description</TH>
              <TH className="th-left">Image Name</TH>
            </TR>
          </thead>
          {reportData?.map((item, index) => (
            <TR key={index}>
              <TD className="th-right">{item?.from_page}</TD>
              <TD className="th-right">{item?.to_page}</TD>
              <TD className="th-left">{item?.exception_type}</TD>
              <TD className="th-left">{item?.check}</TD>
              <TD className="th-right">{item?.amount}</TD>
              <TD className="th-left">{item?.description}</TD>
              <TD className="th-left">
                <Tooltip title={item?.image_name} disableInteractive>
                  <div className="text-truncate" style={{ maxWidth: "100px", margin: "0 auto" }}>
                    <span> {item?.image_name}</span>
                  </div>
                </Tooltip>
              </TD>
            </TR>
          ))}
        </table> */}
        <DataTable columns={columns} data={reportData?.result} customStyles={customStyles} loading={true} />
      </Modal.Body>
    </Modal>
  );
};
export default ExceptionReportModal;

const TH = styled.td`
  font-weight: bold;
`;
const TD = styled.td``;
const TR = styled.tr`
  .th-left {
    text-align: left;
  }
  .th-right {
    text-align: right;
  }
`;
