import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'
import { DASHBOARD, PROFILES } from 'src/constants/routeName'
import { logOut } from 'src/helpers/utils'
import Avatar1 from '../../static/images/users/user-dummy-img.jpg'
import { decryptBackEndResponse, decryptResponse } from 'src/components/common/EncryptDecrypt';
import { Modal } from 'react-bootstrap'
import ReactiveButton from 'reactive-button'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { changeUserPassword } from 'src/context/actions/auth/password/Password'
import createNotification from 'src/components/common/NotificationContainer'
import { _static_messages } from 'src/components/common/Messages'
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineSync } from "react-icons/ai";
import styled, { css, keyframes } from "styled-components";
import { syncXtractData } from 'src/context/actions/common/common'
import { RiH1 } from 'react-icons/ri'
import { ErrorSharp } from '@material-ui/icons'
import { getIsClaimBridge } from 'src/views/pages/auth/Login/functions'

const rotation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(359deg);
}
`;

const ReloadCircle = styled(AiOutlineSync)`
    margin-left:10px;
    color: black;
    cursor:pointer;
    ${props => props.loader && css`
        animation: ${rotation} 1s linear infinite;
    `}
`;
const ModalHeader = styled('p')({
    padding : '20px 0 0 20px',
    fontSize: '1.3rem',
    fontWeight: 600,
  });
  

const secretKey = process.env.REACT_APP_DECRYPT_KEY;

const Header = ({ heading, sync }) => {

    const navigate = useNavigate()
    const [syncLoading, setSyncLoading] = React.useState(false);
    const [loading, setLoading] = React.useState({ loading: false, type: 'idle' });
    const [user, setUser] = useState({})
    const user_type_name = reactLocalStorage.get('user_type_name')
    const user_type_id = reactLocalStorage.get('user_type_id')
    const login_user_type = reactLocalStorage.getObject('login_user_type', {});
    const user_type = reactLocalStorage.getObject('user_type', {});

    const [modelStatus, setModelStatus] = useState(false)
    const [showPassword, setShowPassword] = useState({
        current: false,
        new: false,
        confirm: false
    })
    const validationSchema = Yup.object({
        current_password: Yup.string().required("This field is required."),
        new_password: Yup.string().required("This field is required.").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/, 'Password must contain Min 8 Character. Uppercase letter,Lowercase letter,Number and Special character.'),
        confirm_password: Yup.string().required("This field is required."),

    });
    const passwordFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            current_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema,

        validate: (values) => {
            const errors = {};
            if (values.new_password != '' && values.confirm_password != '' && values.new_password != values.confirm_password) {
                errors.confirm_password = "Password mismatch"
            }
            if (values.new_password !== '' && values.current_password === values.new_password) {
                errors.new_password = "The new password must be different from the old password";
            }
            return errors;
        },
        onSubmit: (values) => {
            setLoading({ loading: true, type: 'loading' });



            const _data = new FormData();
            _data.append("old_password", values.current_password);
            _data.append("password", values.new_password);
            _data.append("confirm_password", values.confirm_password);
            changeUserPassword({ data: _data })((response) => {
                if (response.data?.status) {
                    setLoading({ loading: false, type: 'success' });
                    createNotification('success', `Password changed successfully`);
                    setShowPassword(false)
                    passwordFormik.resetForm()
                    logOut(navigate)

                } else {
                    setLoading({ loading: false, type: 'error' });
                    if (response.data?.status_code === 400) {
                        Object.keys(response.data?.errors).map((key, index) => (
                            createNotification("error", response.data?.errors?.[key][0])
                        ))
                    } else {
                        createNotification("error", _static_messages.DEFAULT);
                    }
                }
            });

        },
    });

    React.useMemo(() =>
        setUser(decryptResponse(JSON.parse(reactLocalStorage.get('user'))))
        , [])

    const handleSyncData = () => {
        setSyncLoading(true)
        syncXtractData()((response) => {
            if (response?.data?.status) {
                setSyncLoading(false)
                createNotification('success', `Sync Successfully Completed`);
            } else {
                setSyncLoading(false)
                createNotification("error", "Error")

            }
        })
    }


    return (
        <header id="page-topbar">
            <div className="layout-width">
                <div className="navbar-header justify-content-between">
                    <div className='page-titile-header'><h4>{heading}</h4></div>
                    <div className="d-flex align-items-center">
                        {(getIsClaimBridge())&&
                        <div style={{color:'#00a5cb',padding:'5px',borderRadius:'5px',border:'1px solid #00a5cb',marginTop:'4px'}} className='me-5 fw-semibold' ><a  href="mailto: support@claimbridge-helpdesk.freshdesk.com">Email Support</a></div>
                        }
                        <div className='me-5 fw-semibold' >Logged in as : {user_type_name}</div>

                        {sync && sync !== '' && sync !== undefined && window.location.pathname === DASHBOARD ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ backgroundColor: 'green', width: '10px', height: '10px', borderRadius: '50%', marginRight: '5px' }}></div>
                                <span style={{ color: 'green', fontWeight: 600 }}>Last Sync : {sync}</span>
                            </div>
                            :
                            <></>
                        }
                        {/* {user?.is_superuser &&
                            <ReloadCircle loader={syncLoading} onClick={() => { handleSyncData() }} />
                        } */}
                        <div className="dropdown d-md-none topbar-head-dropdown header-item">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className="bx bx-search fs-22"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown">
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..."
                                                aria-label="Recipient's username" />
                                            <button className="btn btn-primary" type="submit"><i
                                                className="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>



                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span className="d-flex align-items-center">
                                    <img className="rounded-circle header-profile-user"
                                        src={Avatar1} alt="Header Avatar" />
                                    <span className="text-start ms-xl-2">
                                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{user?.username}</span>
                                    </span>
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <h6 className="dropdown-header">Welcome {user?.username}!</h6>
                                <Link className="dropdown-item" to={PROFILES}><i
                                    className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span
                                        className="align-middle">Profile</span></Link>
                                {
                                    !(login_user_type === 3 || login_user_type === 4) &&
                                    <button className="dropdown-item" onClick={() => setModelStatus(true)}><i
                                        className="mdi mdi-account-key text-muted fs-16 align-middle me-1"></i> <span
                                            className="align-middle">Change Password</span></button>
                                }


                                <button className="dropdown-item" onClick={() => logOut(navigate)}><i
                                    className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                        className="align-middle" data-key="t-logout">Logout</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modelStatus || user?.is_password_reset_required}
                onHide={() => setModelStatus(false)}
                backdrop={`${user?.is_password_reset_required ? "static":"true"}`}
            >
               {  user?.is_password_reset_required ? 
                    <ModalHeader >Change Password</ModalHeader> 
               :
                 <Modal.Header closeButton>
                         <Modal.Title id="contained-modal-title-vcenter">
                             Change Password
                         </Modal.Title>
                 </Modal.Header>}
                 <Modal.Body>

                    <div className='row mb-3'>
                        <div className="col-xxl-12 col-md-12">
                            <div>
                                <label className="form-label">Current Password</label>
                                <div className="form-icon right">
                                    <input
                                        type={showPassword?.current ? "text" : "password"}
                                        className="form-control"
                                        name="current_password"
                                        placeholder="Enter password"
                                        onChange={passwordFormik.handleChange}
                                        value={passwordFormik.values.current_password}
                                    />
                                     <button onClick={() => setShowPassword({ ...showPassword, current: !showPassword?.current })} className=' btn bg-transparent ' type="button" id="password-addon" style={  { position: 'absolute', top: '0px', right: '0px', cursor: 'pointer' }}>
                                     {showPassword?.current ?
                                         <AiOutlineEye /> :
                                         <AiOutlineEyeInvisible />}
                                     </button>
                                   
                                </div>
                                {passwordFormik.touched?.current_password && passwordFormik.errors?.current_password ? (
                                    <div className='text-danger p-2'>{passwordFormik.errors?.current_password}</div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="col-xxl-12 col-md-12">
                            <div>
                                <label className="form-label">New Password</label>
                                <div className="form-icon right">
                                    <input
                                        type={showPassword?.new ? "text" : "password"}
                                        className="form-control"
                                        name="new_password"
                                        placeholder="Enter password"
                                        onChange={passwordFormik.handleChange}
                                        value={passwordFormik.values.new_password}
                                        onBlur={passwordFormik.handleBlur}
                                        isValid={ passwordFormik.touched.new_password && ! passwordFormik.errors.new_password}
                                        isInvalid={ passwordFormik.touched.new_password &&  passwordFormik.errors.new_password}
                                    />
                                    <button onClick={() => setShowPassword({ ...showPassword, new: !showPassword?.new })} className=' btn bg-transparent ' type="button" id="password-addon" style={  { position: 'absolute', top: '0px', right: '0px', cursor: 'pointer' }}>
                                     {showPassword?.new ?
                                         <AiOutlineEye /> :
                                         <AiOutlineEyeInvisible />}
                                     </button>
                                </div>
                                {passwordFormik.touched?.new_password && passwordFormik.errors?.new_password ? (
                                    <div className='text-danger p-2'>{passwordFormik.errors?.new_password}</div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="col-xxl-12 col-md-12">
                            <div>
                                <label className="form-label">Confirm Password</label>
                                <div className="form-icon right">
                                    <input
                                        type={showPassword?.confirm ? "text" : "password"}
                                        className="form-control"
                                        name="confirm_password"
                                        placeholder="Enter password"
                                        onChange={passwordFormik.handleChange}
                                        value={passwordFormik.values.confirm_password}
                                    />
                                    <button onClick={() => setShowPassword({ ...showPassword, confirm: !showPassword?.confirm })} className=' btn bg-transparent ' type="button" id="password-addon" style={  { position: 'absolute', top: '0px', right: '0px', cursor: 'pointer' }}>
                                     {showPassword?.confirm ?
                                         <AiOutlineEye /> :
                                         <AiOutlineEyeInvisible />}
                                     </button>
                                </div>
                                {passwordFormik.touched?.confirm_password && passwordFormik.errors?.confirm_password ? (
                                    <div className='text-danger p-2'>{passwordFormik.errors?.confirm_password}</div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ReactiveButton
                        color="teal"
                        idleText="Submit"
                        buttonState={loading.type}
                        onClick={passwordFormik.handleSubmit}
                        disabled={loading.loading}
                        loadingText="Updating.."

                    />
                </Modal.Footer>
            </Modal>
        </header>
    )
}

export default Header
