import axiosInstance from "src/helpers/axiosInstance";

import CryptoJS from 'crypto-js';
import { encryptPlaintext } from "src/views/pages/auth/Login/functions";

const base64Key = process.env.REACT_APP_DECRYPT_KEY

export const userLoginApi = (props) => onResponse => {

    try {

        const plaintext = props.data.get('password')
        const ciphertext = encryptPlaintext(plaintext)

        axiosInstance.post("/auth/login", {
            email: props.data.get('email'),
            password: ciphertext,

        })
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};

export const userLogoutApi = (props) => onResponse => {

    try {
        axiosInstance.post("/auth/logout", {
            refresh: props.data.get('refresh'),
        })
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};

export const userForceLogoutApi = (props) => onResponse => {

    try {
        axiosInstance.post("/auth/logout", {
            refresh: props.data.get('refresh'),
        },
            {
                headers: {
                    Authorization: 'Bearer ' + props.data.get('token'),
                }
            })
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};





export const verifyTwoFactorAuth = (props) => onResponse => {

    try {
        axiosInstance.post("/2fa/verify-two-factor-auth", { 'code': props.code, 'secret_key': props.secret_key, qr_flag: props?.qr_flag ? true : false })
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};
export const verifyTwoFactorAuth1 = (props) => onResponse => {

    try {
        axiosInstance.post("/2fa/verify-auth-two-factor", { 'user': props.user, 'code': props.code, 'secret_key': props.secret_key, qr_flag: props?.qr_flag ? true : false })
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};
export const getUserPermission = (props) => onResponse => {
    let baseURL = '/auth/permission-list?';
    if (props?.user_type) {
        baseURL = baseURL + 'user_type=' + props.user_type + '&';
    }
    if (props?.user_type) {
        baseURL = baseURL + 'user_type_id=' + props.user_type_id + '&';
    }
    try {
        axiosInstance.post(baseURL)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};
export const getUserPermissionWithLogo = (props) => onResponse => {
    let baseURL = '/auth/permission-logo-list?';
    if (props?.user_type) {
        baseURL = baseURL + 'user_type=' + props.user_type + '&';
    }
    if (props?.user_type) {
        baseURL = baseURL + 'user_type_id=' + props.user_type_id + '&';
    }
    try {
        axiosInstance.post(baseURL)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {
        console.log(error)
    }
};

export const impersonateUser = (props) => onResponse => {

    try {
        axiosInstance.post("/auth/impersonate", props)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};
export const createTermsAndConditions = (props) => onResponse => {
    try {
        axiosInstance.post("/users/accept-terms-and-conditions", props)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};
