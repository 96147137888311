
import { AES, enc } from 'crypto-js'
import CryptoJS from 'crypto-js';


const secretKey = process.env.REACT_APP_SECRET_KEY

const encryptResponse = (data) => {
    const ciphertext = AES.encrypt(data, secretKey).toString();

    return ciphertext

}



const decryptResponse = (data) => {
    let json_decrypted_text = null
    if (data) {
        var decrypted = AES.decrypt(data, secretKey);
        const plaintext = decrypted.toString(enc.Utf8);
        json_decrypted_text = JSON.parse(plaintext)
    }
    return json_decrypted_text

}


const decryptBackEndResponse = ({ base64Key, encryptedData }) => {
    const key = CryptoJS.enc.Utf8.parse(base64Key);
    const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
    const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
    const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));
    const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });

    const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);

    const outdata = JSON.parse(decryptedText);

    return outdata;
};





export { encryptResponse, decryptResponse, decryptBackEndResponse }