import CryptoJS from 'crypto-js';
import Proxy from 'src/components/common/Redirect/Proxy';

export const encryptPlaintext = (plaintext) => {
    var iv = CryptoJS.lib.WordArray.random(16);

    // Convert the key and IV to WordArray objects
    var keyBytes = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPT_KEY);
    var ivBytes = CryptoJS.enc.Hex.parse(iv.toString());

    // Perform the encryption using AES-CBC mode
    var encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
        iv: ivBytes,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    // Combine the IV and ciphertext into a single string
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}



export const getIsClaimBridge = () => {
    const domain = window.location.origin;
    return domain == "https://clrevarc.aventusinformatics.com" || domain == "https://poc.claimbridgehealth.com" || domain == "https://experian.claimbridgehealth.com" || domain == "https://trizettoprovider.claimbridgehealth.com" || domain == "https://uat-trizettoprovider.claimbridgehealth.com" || domain == "https://portal.claimbridgehealth.com"

}


export const handleDownloadFileUrl = async (file,isOpenDownload=false) => {
    try {
        if(isOpenDownload){
            window.open(Proxy(file), "_blank")
        }else{
            // Extract the file name and extension from the URL
            const OgUrl = file.split('?')[0]
            const fileName = OgUrl.split('/').pop();
            const response = await fetch(file);
            const blob = await response.blob();
            const blobURL = URL.createObjectURL(blob);
    
            let alink = document.createElement('a');
            alink.href = blobURL;
            alink.download = fileName; // Set the extracted file name
            alink.click();
    
            // Clean up the Blob URL
            URL.revokeObjectURL(blobURL);
        }

    } catch (error) {
        console.error('Error downloading the file:', error);
    }
}